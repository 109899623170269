import React, { useEffect } from "react"
import Layout from "../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../utils/misc'


const mainStyles = {
    display: "flex",
    flexDirection: "column"
}

const pageStyle = {
    width: "54rem",
    margin: "auto",
    padding: "1rem 2rem",
    marginBottom: "3rem",
    // maxWidth: "calc(100% - 4rem)"
    maxWidth: "100%"
}

const AboutPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
    }, [])

    return (
        <Layout>
            <main style={mainStyles}>
                <div style={pageStyle}>
                    <h1 className="title" style={{ textAlign: "center" }}>{t('about.title')}</h1>
                    <section>
                        <p className="fletter">{t('about.sections.0.0')}</p>
                        <p style={{ textAlign: "center" }}>{t('about.sections.0.1')}</p>
                        <p>{t('about.sections.0.2')}</p>
                    </section>
                    <section>
                        <p className="fletter">{t('about.sections.1.0')}</p>
                        <p style={{ textAlign: "center" }}>{t('about.sections.1.1')}</p>
                    </section>
                    <h2 style={{ textAlign: "center", marginTop: "4rem" }}>{t('about.sections.2.0')}</h2>
                    <section>
                        <b>{t('about.sections.3.0')}</b>
                        <p>{t('about.sections.3.1')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.4.0')}</b>
                        <p>{t('about.sections.4.1')}</p>
                        <p>{t('about.sections.4.2')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.5.0')}</b>
                        <p>{t('about.sections.5.1')}</p>
                        <p>{t('about.sections.5.2')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.6.0')}</b>
                        <p>{t('about.sections.6.1')}</p>
                        <p>{t('about.sections.6.2')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.7.0')}</b>
                        <p>{t('about.sections.7.1')}</p>
                        <p>{t('about.sections.7.2')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.8.0')}</b>
                        <p>{t('about.sections.8.1')}</p>
                        <p>{t('about.sections.8.2')}</p>
                        <p>{t('about.sections.8.3')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.9.0')}</b>
                        <p>{t('about.sections.9.1')}</p>
                        <br />
                    </section>
                    <section>
                        <b>{t('about.sections.10.0')}</b>
                        <p>{t('about.sections.10.1')} <a rel="nofollow" href="mailto:team@takeaseat.io" target="_blank" style={{ color: "#0645AD" }}>{t('about.sections.10.2')}</a> {t('about.sections.10.3')}</p>
                        <br />
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default AboutPage

export const Head = () => {
    const { t } = useTranslation();

    return <>
        <DefaultGtag />
        {/* <link rel="canonical" href="https://takeaseat.io/about/" /> */}
        <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/about/" />
        <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/about/" />
        <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/about/" />
        <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/about/" />
        <title>About Take a Seat, digital partner of restaurants</title>
        <meta name="description" content="About page - Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="The digital partner of restaurants and bars" />
        <meta property="og:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
        <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
        <meta property="og:url" content="https://takeaseat.io/" />
        <meta property="og:site_name" content="Take a Seat" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="The digital partner of restaurants and bars" />
        <meta name="twitter:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
        <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
        <meta name="twitter:site" content="@Takeaseat_io" />
        <meta name="twitter:creator" content="@Takeaseat_io" />
    </>
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","about", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;